import React, { useState, useEffect } from "react";
import { MenuItem } from "../MenuItem/MenuItem";
import "./style.css";
import logo from "../../../static/img/logo-1.png";
import { RoutesConstants } from "../../routes/routesConstants";

const menuItems = [
  { text: "Diferenciais", sectionId: "section-4" },
  { text: "Quem somos", sectionId: "section-1" },
  { text: "Aplicativo", sectionId: "section-2" },
  { text: "Profissionais", sectionId: "section-5" },
  { text: "Planos", href: RoutesConstants.plans },
];

export const Menu: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      setMenuVisible(
        currentScrollPosition <= lastScrollPosition ||
        currentScrollPosition === 0
      );
      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  useEffect(() => {
    if (activeSection) {
      document.body.classList.add("menu-active");
    } else {
      document.body.classList.remove("menu-active");
    }
  }, [activeSection]);

  const scrollToSection = (sectionId: string) => {
    // Verifica se a rota atual é a raiz "/"
    if (window.location.pathname === "/") {
      // Executa o scroll diretamente se já estiver na página inicial
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        setActiveSection(sectionId);
      }
    } else {
      // Redireciona para "/" com o hash do ID da seção
      window.location.href = `/#${sectionId}`;
    }
  };

  return (
    <div className={`menu ${isMenuVisible ? "visible" : "hidden"}`}>
      <a href="/">
        <img className="logo" alt="Logo" src={logo} />
      </a>
      <div className="div-5">
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            text={item.text}
            onClick={() => scrollToSection(item.sectionId)}
            href={item.href}
          />
        ))}
      </div>
    </div>
  );
};
