import React from "react";
import { PlanCard } from "../../components/PlanCard/PlanCard";
import './style.css'
import { Button } from "../../components/Button";
import ContactImage from '../../../static/img/contact.png'

export const Plans = (): JSX.Element => {
  return (
    <section id="section-4" style={{ paddingTop: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 36 }}>
      <h2 className="section-title">Invista em Inovação para o Cuidado com seus Pacientes</h2>
      <h3 className="section-title" style={{ fontSize: 32, color: '#3a3a3a' }}>Comece Grátis</h3>
      <p className="section-description">
        Experimente a tecnologia de ponta da Wellny, desenvolvida para elevar o engajamento dos pacientes através de um fluxo e atendimento de excelência.
      </p>
      <p className="section-description">
        Escolha um produto para começar:
      </p>
      <div style={{ display: 'flex', gap: 24, paddingBottom: 60, flexWrap: 'wrap', paddingLeft: 20, paddingRight: 20 }}>
        <PlanCard
          title="Basic"
          price={200}
          features={["Até 50 pacientes em tratamento", "Modelo de Inteligência para visão integrada", "Transcrição por áudio", "1 usuário"]}
          alert={"Volume restrito de usuários e pacientes em tratamento. Cancele a qualquer momento."}
          isHighlighted
        />
        <PlanCard
          title="Standard"
          price={250}
          features={["Até 100 pacientes em tratamento", "Modelo de Inteligência para visão integrada", "Transcrição por áudio", "2 usuários"]}
          alert={"Volume maior de pacientes em tratamento, podendo convidar um usuário adicional. Cancele a qualquer momento."}
          isHighlighted
        />
        <PlanCard
          title="Premium"
          price={300}
          isHighlighted
          features={[
            "A partir de 120 pacientes em tratamento",
            "Desconto para volumes maiores de pacientes em tratamento",
            "Modelo de Inteligência para visão integrada",
            "Transcrição por áudio",
            "5 usuários"
          ]}
          alert={"Ideal para maior volume de pacientes em tratamento a um preço mais acessível. Você define o volume que mais se adequa à sua necessidade. Cancele a qualquer momento."}
        />

        <div className={`plan-card`} style={{ display: 'flex', flexDirection: 'column', gap: 22, alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="price-section">
            <h3 className="title" style={{ color: '#2a2828', marginBottom: 12 }}>Contate a Wellny</h3>
            <p className="price-subtext">Para ajuda ou para solicitar outro plano que melhor se encaixa ao seu negócio.</p>
          </div>

          <img
            src={ContactImage}
            width={250}
            height={250}
            alt="Contact Image"
          />

          <a href="https://wellny.tawk.help/" target="_blank" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              text="Entre em contato"
              status={"primary"}
              icon={"dir"}
              color={"on"}
              className={undefined}
            />
          </a>
        </div>
      </div>
    </section>
  );
};
