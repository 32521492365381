import React from "react";
import googlePlayImg from "../../icons/AppsStore/play.png";
import appStoreImg from "../../icons/AppsStore/apple.png";
import "./style.css";

export const ButtonGroup: React.FC = () => {
  return (
    <div className="button-group-container">
      <a
        href="https://apps.apple.com/br/app/wellny-health/id6569225434"
        className="button-group-button app-store-button"
      >
        <img
          className="button-group-logo app-store-logo"
          src={appStoreImg}
          alt="App Store"
        />
        <div className="button-group-content">
          <p className="button-group-text-paragrafo">GET IT ON</p>
          <p className="button-group-text">App Store</p>
        </div>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=kognit.wellny"
        className="button-group-button google-play-button"
      >
        <img
          className="button-group-logo google-play-logo"
          src={googlePlayImg}
          alt="Google Play"
        />
        <div className="button-group-content">
          <p className="button-group-text-paragrafo">GET IT ON</p>
          <p className="button-group-text">Google Play</p>
        </div>
      </a>
    </div>
  );
};
