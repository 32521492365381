import React, { useState, useEffect } from "react";
import { MenuItem } from "../MenuItem/MenuItem";
import { RoutesConstants } from "../../routes/routesConstants";
import "./style.css";
import { Link } from "react-router-dom";
import logo from "../../../static/img/logo.png";
import iconLinkedIn from "../../../static/img/icon-linkedin.svg";
import iconInstagram from "../../../static/img/icon-instagram.svg";

const menuItems = [
  { text: "Diferenciais", sectionId: "section-4" },
  { text: "Quem somos", sectionId: "section-1" },
  { text: "Aplicativo", sectionId: "section-2" },
  { text: "Profissionais", sectionId: "section-5" },
  { text: "Planos", href: RoutesConstants.plans },
];

export const Footer: React.FC = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      setMenuVisible(
        currentScrollPosition <= lastScrollPosition ||
        currentScrollPosition === 0
      );
      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  useEffect(() => {
    if (activeSection) {
      document.body.classList.add("menu-active");
    } else {
      document.body.classList.remove("menu-active");
    }
  }, [activeSection]);

  const scrollToSection = (sectionId: string) => {
    // Verifica se a rota atual é a raiz "/"
    if (window.location.pathname === "/") {
      // Executa o scroll diretamente se já estiver na página inicial
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        setActiveSection(sectionId);
      }
    } else {
      // Redireciona para "/" com o hash do ID da seção
      window.location.href = `/#${sectionId}`;
    }
  };

  return (
    // <div className={`menu ${isMenuVisible ? 'visible' : 'hidden'}`}>
    <div className="containerFooter" style={{ zIndex: 99999 }}>
      <footer className="footer">
        <a href="/">
          <img className="logo" alt="Logo" src={logo} />
        </a>


        <div className="div-7">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              text={item.text}
              onClick={() => scrollToSection(item.sectionId)}
              href={item.href}
            />
          ))}
        </div>

        <div className="box-SocialButtons">
          <a href="https://www.linkedin.com/company/wellny-oficial/">
            <img
              className="social-buttons"
              alt="Social buttons"
              src={iconLinkedIn}
            />
          </a>
          <a href="https://www.instagram.com/wellny_br/">
            <img
              className="social-buttons"
              alt="Social buttons"
              src={iconInstagram}
            />
          </a>
        </div>
      </footer>
      <div className="containerCopyright">
        <div className="copyrightLinks">
          <Link to={RoutesConstants.termsOfUse}>Termos de Uso</Link>
          <Link to={RoutesConstants.privacyTerms}>Política de Privacidade</Link>
        </div>
        <p className="copyrightText">
          Direitos Autorais © 2024 Wellny Todos os Direitos Reservados.
        </p>
      </div>
    </div>
  );
};
