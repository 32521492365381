import PropTypes from "prop-types";
import React from "react";
import { VuesaxLinearArrowCircleRight2 } from "../../icons/VuesaxLinearArrowCircleRight2";
import { VuesaxLinearArrowCircleRight3 } from "../../icons/VuesaxLinearArrowCircleRight3";
import { VuesaxLinearArrow } from "../VuesaxLinearArrow";
import "./style.css";

interface Props {
  status: "primary" | "secondary";
  icon: "esq" | "off" | "dir";
  color: "off" | "on";
  className: any;
  text: string;
  onCLick?: () => void;
}

export const Button = ({ status, icon, color, className, text = "Placeholder", onCLick }: Props): JSX.Element => {
  return (
    <button className={`button ${icon} ${color} ${status} ${className}`} onClick={onCLick}>
      {icon === "esq" && <VuesaxLinearArrowCircleRight3 className="vuesax-linear-arrow" />}

      <div className="placeholder">{text}</div>
      {icon === "dir" && (
        <VuesaxLinearArrowCircleRight2 className="vuesax-linear-arrow" color={color === "off" ? "#EA7526" : "white"} />
      )}

      {icon === "off" && status === "primary" && <VuesaxLinearArrow />}
    </button>
  );
};

Button.propTypes = {
  status: PropTypes.oneOf(["primary", "secondary"]),
  icon: PropTypes.oneOf(["esq", "off", "dir"]),
  color: PropTypes.oneOf(["off", "on"]),
  text: PropTypes.string,
};
