/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { VuesaxLinearArrowCircleRight4 } from "../../icons/VuesaxLinearArrowCircleRight4";
import "./style.css";

export const VuesaxLinearArrow = (): JSX.Element => {
  return <VuesaxLinearArrowCircleRight4 className="vuesax-linear-arrow-circle-right-4" />;
};
