import React from "react";
import "./style.css";

import { IconPlanning } from "../../icons/IconPlanning";
import { IconSpeed } from "../../icons/IconSpeed";
import rectangle7 from "../../../static/img/rectangle-7.svg";
import rectangle5 from "../../../static/img/rectangle-5.svg";
import maskGroup from "../../../static/img/mask-group.png";
import image2 from "../../../static/img/image-2.png";
import arabesco from "../../../static/img/arabesco.png";
import image1 from "../../../static/img/image-1.png";
import telaProfissionais from "../../../static/img/TelaProfissional.png";
import fotoRogerio from "../../../static/img/foto-roge-rio.png";
import fotoMarcelo from "../../../static/img/foto-marcelo.png";
import { ButtonGroup } from "../../components/Button/ButtonApp";
import { Button } from "../../components/Button";

export const LandingPage = (): JSX.Element => {
  return (
    <div id="section-0">

      <div className="div-6">
        <div className="overlap-group-2">
          <img className="rectangle" alt="Rectangle" src={rectangle7} />
          <img className="rectangle-2" alt="Rectangle" src={rectangle5} />
          <img className="mask-group" alt="Mask group" src={maskGroup} />

          <div className="text-block">
            <div className="text-5">
              <p className="cuidado-que-evolui">
                Cuidado que evolui com você
              </p>
              {
                <p className="text-wrapper-21">
                  Nossa plataforma completa se integra à sua vida, aumentando
                  sua consciência e autonomia. Promovemos uma jornada de
                  cuidado preventiva, preditiva e resolutiva. Com
                  recomendações personalizadas, você pode acompanhar sua
                  evolução e ajustar seu caminho para alcançar um bem-estar
                  crescente
                </p>
              }
              {/* <p className="text-wrapper-21">
                  Um ecossistema de integração entre profissionais de saúde e
                  paciente que potencializa a autonomia, o empoderamento e o
                  protagonismo das pessoas que buscam mais bem-estar.
                </p> */}
            </div>
            <div className="">
              <ButtonGroup />
            </div>
          </div>
          <img className="image-2" alt="Image" src={image2} />
        </div>
      </div>

      <div className="" style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div className="o-que-fazemos" id="section-4" style={{ paddingBottom: 100 }}>
          <div className="content-2">
            <p className="text-wrapper">Estar bem é estar Wellny</p>
            <p className="onde-a-paixa-o-por">
              Onde a paixão por tecnologia se uniu a um olhar holístico sobre
              a saúde para incentivar o cuidado preventivo e preditivo.
              <br />
              Nossa Inteligência Artificial caminha ao lado das pessoas,
              funcionando como um organismo vivo e integrado que é capaz de
              aprender e evoluir. Proporcionando recomendações com
              assertividade, ampliamos nossa capacidade de conhecer mais sobre
              nós mesmos.
            </p>
          </div>

          <div className="itens">
            <div className="element">
              <IconPlanning className="icon" color="#EA7526" />
              <p className="text-wrapper-2">
                Faça testes e descubra seu score
              </p>
              <p className="p">
                Descubra seu score de saúde com nosso app de bem-estar! De
                forma rápida e simples, guiaremos você por um conjunto de
                questões cuidadosamente elaboradas para avaliar sua situação
                de saúde atual. Basta seguir o passo a passo em nosso
                aplicativo e, em poucos minutos, você obterá seu resultado. É
                uma maneira eficiente e conveniente de monitorar seu bem-estar
                geral. Experimente agora e fique por dentro de como está sua
                saúde!
              </p>
            </div>
            {
              // <div className="element-2">
              //   <Icon1 className="icon" color="#EA7526" />
              //   <div className="text-wrapper-2">
              //     Encontre o profissional correto
              //   </div>
              //   <p className="p">
              //     Encontre o profissional de saúde ideal com a Wellny! Nossa
              //     plataforma integrada conecta você a especialistas que
              //     oferecem um atendimento diferenciado e personalizado. Com
              //     uma abordagem holística e empática, nossos profissionais
              //     focam na prevenção de saúde e compreendem que os sintomas
              //     clínicos são o resultado de diversos fatores relacionados à
              //     experiência individual. Assim, o tratamento é planejado
              //     considerando você como um todo. Acesse a Wellny e comece sua
              //     jornada para um cuidado de saúde verdadeiramente
              //     personalizado e eficaz.
              //   </p>
              // </div>
            }
            <div className="element-2">
              <IconSpeed className="icon" color="#EA7526" />
              <div className="text-wrapper-2">Veja sua evolução</div>
              <p className="p">
                Veja sua evolução com nosso app! À medida que você realiza
                novas avaliações, nosso aplicativo permite que você visualize
                seu progresso. Acompanhe as recomendações personalizadas
                baseadas nas informações que você forneceu sobre seus hábitos
                e outros aspectos relevantes da sua saúde. Com nosso sistema
                intuitivo, é fácil monitorar as mudanças e ajustar seu caminho
                para um bem-estar cada vez maior. Experimente e observe sua
                evolução em tempo real!
              </p>
            </div>
          </div>
        </div>
        <div className="element-3" id="section-1">
          <div className="frame">
            <div className="overlap-group">
              <div className="VERDE" />
              <img className="ARABESCO" alt="Arabesco" src={arabesco} />
            </div>
          </div>
          <div className="text" id="section-1">
            <div className="text-wrapper-3">A Wellny</div>
            <p className="wellny-e-se-encantar">
              Wellny é se encantar por uma tecnologia capaz de abraçar vidas e
              acreditar que as inovações feitas por pessoas devem ser usadas a
              favor das pessoas. Enquanto o mundo segue evoluindo, nós
              expandimos a capacidade de conhecer mais sobre nós mesmos.
              <br />
              <br />
              Na Wellny incentivamos um amanhã onde as pessoas vivam sua
              melhor trajetória, onde olhar para a saúde seja mais leve. Para
              nós, cuidar de uma pessoa que é única significa cuidar do mundo
              inteiro, afinal, impactos positivos geram impactos melhores
              ainda.
            </p>
            <p className="text-wrapper-4">
              Te convidamos a revolucionar sua jornada de saúde. Te convidamos
              a ser Wellny!
            </p>
            <div className="">
              <ButtonGroup />
            </div>
          </div>
        </div>

        <div className="screenshots" id="section-2" style={{ paddingTop: 200, display: "flex", flexDirection: "column", gap: 36 }}>
          <div className="element-4">
            <div className="text-2">
              <div className="text-wrapper-5">Seu parceiro de bem-estar</div>
              <p className="text-wrapper-6">
                Descubra e acompanhe sua saúde com nosso app! De maneira
                rápida e simples, guiamos você através de questões elaboradas
                para avaliar sua saúde atual. Siga o passo a passo e obtenha
                resultados em minutos. Além disso, conforme realiza novas
                avaliações, você pode visualizar sua evolução e receber
                recomendações personalizadas baseadas em seus hábitos e outros
                aspectos relevantes. Nosso sistema intuitivo facilita o
                monitoramento e ajuste do seu caminho para um bem-estar cada
                vez maior. Experimente agora e observe sua saúde melhorar em
                tempo real!
              </p>
              <div className="">
                <ButtonGroup />
              </div>
            </div>
            <div className="images">
              <img className="image" alt="Image" src={image1} />
            </div>
          </div>

          <div
            id="section-5"
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
              paddingLeft: '5%',
              paddingRight: '5%',
              flexWrap: 'wrap'
            }}
          >

            <img
              className="image"
              alt="Image"
              src={telaProfissionais}
              width={600}
              height={500}
              style={{
                objectFit: 'contain',
                borderRadius: 8,
                width: '100%',
                maxWidth: 600
              }}
            />

            <div className="text-2">
              <div className="text-wrapper-5" style={{ flexWrap: 'wrap' }}>Para profissionais</div>
              <p className="text-wrapper-6" style={{ flexWrap: 'wrap' }}>
                A Wellny é sinônimo de inovação e tecnologia desenhadas para melhorar vidas, com o compromisso de usar avanços tecnológicos em benefício das pessoas.

                Com a Wellny como sua parceira, você notará um aumento significativo no engajamento e satisfação dos seus pacientes, pois provemos funcionalidades constantemente atualizadas que facilitam e aprimoram seu atendimento.

                Conte sempre conosco para inovar e aprimorar sua experiência e seu fluxo de trabalho, pois buscamos incansavelmente as melhores soluções para você.
              </p>
              <a href="https://app.wellny.com.br/login?createAccount=true" target="_blank" className="">
                <Button text="Comece Grátis" status={"primary"} icon={"dir"} color={"on"} className={undefined} />
              </a>
            </div>
          </div>
        </div>

        <div className="frame-3" style={{ paddingBottom: 100, paddingTop: 100 }}>
          <div className="text-wrapper-5">Founders</div>
          <div className="content-3">
            <div className="div-2">
              <img
                className="foto-rogerio"
                alt="Foto rogerio"
                src={fotoRogerio}
              />
              <div className="text-4">
                <div className="text-wrapper-9">
                  Dr. Rogério Augusto Queiroz{" "}
                </div>
                <p className="fisioterapeuta">
                  Fisioterapeuta graduado pela Pontifícia Universidade
                  Católica de Campinas e Osteopata D.O. pela Escola de
                  Osteopatia Madrid / S.E.F.O.
                  <br />
                  Especialista em Educação pela UNICAMP e mestre em Anatomia
                  pela Universidade de Cádiz, na Espanha. Possui MBA em Gestão
                  e Empreendedorismo pela Metrocamp/DeVry. É editor
                  internacional do Journal of American Osteopathic
                  Association, Presidente da Osteopatia Sem Fronteiras (OSF),
                  CEO da Escuela de Osteopatía de Madrid Brasil
                </p>
              </div>
            </div>
            <div className="div-2">
              <div className="text-4">
                <div className="text-wrapper-10">Marcelo Rosa</div>
                <p className="fisioterapeuta-2">
                  Fundador e CEO da Kognit, cofundador e CTO da Veridex.{" "}
                  <br />
                  Investidor e Board Member em startups dos segmentos de
                  Cibersegurança, Finanças, Automotivo e Saúde. <br />
                  +30 anos de experiência estratégica em Tecnologia
                </p>
              </div>
              <img
                className="foto-marcelo"
                alt="Foto marcelo"
                src={fotoMarcelo}
              />
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};
