/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const VuesaxLinearArrowCircleRight3 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`vuesax-linear-arrow-circle-right-3 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.99992 18.3334C5.39754 18.3334 1.66658 14.6025 1.66658 10.0001C1.66658 5.39771 5.39754 1.66675 9.99992 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99992 18.3334Z"
        stroke="#EA7526"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.25"
      />
      <path
        className="path"
        d="M11.05 12.9417L8.11671 10L11.05 7.05835"
        stroke="#EA7526"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </svg>
  );
};
