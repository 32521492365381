/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const BulletCheck7 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`bullet-check-7 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle" cx="11" cy="11" r="9" stroke="#EA7526" strokeWidth="1.2" />
      <path
        className="path"
        d="M13.5 2.5H9.24862C5.97099 2.5 2.50001 6.224 2.50001 9.5L2 12.602C2 15.887 5.22237 19.5 8.5 19.5L12.5 20C15.7776 20 19.5 16.776 19.5 13.5V10C19.509 6.724 16.7776 2.5 13.5 2.5ZM15.3086 8.93L10.2031 14.033C10.077 14.159 9.90595 14.231 9.72586 14.231C9.54577 14.231 9.37468 14.159 9.24862 14.033L6.70035 11.486C6.43922 11.225 6.43922 10.793 6.70035 10.532C6.96148 10.271 7.39369 10.271 7.65482 10.532L9.72586 12.602L14.3542 7.976C14.6153 7.715 15.0475 7.715 15.3086 7.976C15.5698 8.237 15.5698 8.66 15.3086 8.93Z"
        fill="#EA7526"
      />
    </svg>
  );
};
