import React from "react";
export const PrivacyTerms = (): JSX.Element => {
  return (
    <div className="container-page container-privacy-terms privacy-terms-text">
      <h2 className="text-wrapper-16">Política de Privacidade</h2>
      <br />
      <p className="text-wrapper-22">
        Obrigado por escolher Wellny. Nosso objetivo é fornecer insights sobre a
        situação da sua saúde a qualquer momento e capacitá-lo a tomar as
        melhores decisões baseadas em diagnósticos. Por isso, criamos uma
        plataforma online e fácil de usar, baseada em dados, para permitir que
        você verifique a sua situação de saúde em minutos. Por esse motivo, os
        dados são essenciais para nossa missão e, como seu controlador de dados,
        levamos muito a sério o respeito e a proteção de sua privacidade e
        segurança. Estamos comprometidos em seguir e evoluir continuamente as
        melhores práticas para apoiar este princípio. Nós – ou seja, Wellny e
        suas empresas afiliadas (coletivamente denominadas “Wellny”, “nós” ou
        “nossa”) – preparamos esta Política de Privacidade para ajudá-lo a
        entender:
      </p>
      <p className="text-wrapper-22 text-bold">
        Quais informações coletamos sobre você e como
      </p>
      <ul className="text-wrapper-22">
        <li>Como usamos as informações que coletamos</li>
        <li>Como compartilhamos as informações que coletamos</li>
        <li>Como armazenamos e protegemos as informações</li>
        <li>Como acessar e gerenciar seus dados</li>
        <li>Outras informações de privacidade</li>
      </ul>
      <p className="text-wrapper-22">
        Esta Política de Privacidade abrange as informações que coletamos
        através dos sites da Wellny (www.wellny.com.br, incluindo subpáginas e
        subdomínios) ou plataforma online (Aplicativo móvel), (os “Produtos”),
        nossos sites que vinculam a esta Política de Privacidade e quaisquer
        outros serviços fornecemos aos nossos clientes ou usuários finais
        (coletivamente com os Produtos, os “Serviços”). A Wellny respeita a LGPD
        (Lei Geral de Proteção de Dados).
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Quais informações coletamos sobre você e como
      </h4>
      <p className="text-wrapper-22">
        Coletamos informações sobre você de diferentes maneiras, dependendo dos
        Serviços que você usa e de sua atividade em nosso site e plataforma. De
        um modo geral, coletamos informações de você quando você as fornece a
        nós e quando usa nossos Serviços, conforme descrito abaixo.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Informações que você nos fornece
      </h4>
      <p className="text-wrapper-22">
        Para navegar em nosso site, você não precisa fornecer nenhuma
        informação, a menos que queira assinar nossa newsletter ou entrar em
        contato conosco. No entanto, para fazer uso de nossos Produtos,
        precisamos de informações adicionais para você configurar sua própria
        conta conosco.
      </p>
      <h4 className="text-wrapper-22 text-bold">Informações da conta</h4>
      <p className="text-wrapper-22">
        Pedimos informações sobre você cria uma conta para seu uso. Para usar
        determinados Produtos ou Serviços, exigimos o registro da conta. Isso
        requer um nome completo associado à sua conta, um número de telefone
        celular, um endereço de e-mail pelo qual podemos entrar em contato com
        você e uma senha para ajudar a proteger suas informações pessoais.
      </p>
      <h4 className="text-wrapper-22 text-bold">Informações de contato</h4>
      <p className="text-wrapper-22">
        Coletamos e armazenamos conteúdo que você insere, envia, carrega, cria,
        compartilha ou exibe no processo de uso de nossos Serviços. Este
        conteúdo inclui qualquer informação que você decida incluir, incluindo
        os arquivos e links que você enviar para o Serviço.
        <p className="text-wrapper-22">
          Exemplos de Conteúdo coletamos e armazenamos incluem: as respostas às
          avaliações de Sono, Alimentação, Dor, Stress, Atividades Físicas e
          trabalho.
        </p>
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Conteúdo que você fornece por meio de nossos sites
      </h4>
      <p className="text-wrapper-22">
        Coletamos outros conteúdos que você envia para nossos sites, que incluem
        redes sociais ou sites de redes sociais operados por nós. Por exemplo,
        você nos fornece conteúdo quando dá feedback ou quando participa de
        recursos interativos, pesquisas, concursos, promoções, atividades ou
        eventos.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Informações que você fornece por meio de nossos canais de suporte
      </h4>
      <p className="text-wrapper-22">
        Os Serviços também incluem nosso suporte ao cliente, onde você pode
        optar por enviar informações sobre um problema que está enfrentando com
        os Serviços. Quer você nos envie um e-mail, entre em contato por meio
        dos Produtos, fale diretamente com um de nossos representantes ou entre
        em contato com nossa equipe de suporte, você pode ser solicitado a
        fornecer informações de contato, um resumo do problema que está
        enfrentando e qualquer outra documentação, capturas de tela ou
        informações que seriam úteis para resolver o problema.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Informações de pagamento
      </h4>
      <p className="text-wrapper-22">
        Quando você se registra em determinados Serviços pagos a Wellny não
        armazenará ou reterá quaisquer outras informações de cobrança sobre
        você. A Wellny contrata terceiros, nomeadamente a Cielo, para processar
        o seu pagamento de forma segura. Ambas as partes são compatíveis com PCI
        DSS. Esses provedores de serviços terceirizados podem ter acesso ao seu
        endereço de e-mail para poder fornecer seus serviços para nós. Esses
        provedores de serviços não têm permissão para armazenar, reter ou usar
        as informações que você fornece, exceto para o único propósito de
        processamento de pagamentos em nosso nome.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Informações que coletamos automaticamente quando você usa os Serviços
      </h4>
      <p className="text-wrapper-22">
        Coletamos informações sobre você quando você usa nossos Serviços,
        incluindo navegar em nossos sites e realizar determinadas ações dentro
        dos Serviços.
      </p>
      <h4 className="text-wrapper-22 text-bold">Informações do perfil</h4>
      <p className="text-wrapper-22">
        Para garantir uma experiência tranquila e agradável ao usar nossos
        serviços, coletamos informações sobre você sobre suas preferências de
        perfil, incluindo suas preferências de idioma, notificações e
        preferências de marketing. Estes podem ser armazenados na forma de
        cookies, pequenos arquivos que são mantidos em seu navegador e que nos
        informam suas preferências na próxima vez que você visitar nosso site ou
        usar nossos Serviços. Alguns cookies são opcionais, outros não, e são
        explicados com mais detalhes no Cookies and Tracking Notice.
      </p>
      <h4 className="text-wrapper-22 text-bold">Seu uso dos Serviços</h4>
      <p className="text-wrapper-22">
        Acompanhamos certas informações sobre você quando você visita e interage
        com qualquer um de nossos Serviços. Essas informações incluem os
        recursos que você usa, os links nos quais clica, termos de pesquisa
        usados com frequência e outras estatísticas relacionadas ao uso dos
        Serviços.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Informações sobre dispositivos e conexões
      </h4>
      <p className="text-wrapper-22">
        Não coletamos informações de dispositivo e conexão (como endereços IP,
        tipos de navegador, sistemas operacionais, ISPs, tipo de plataforma,
        tipo de dispositivo etc.), mas usamos ferramentas de terceiros, que
        coletam esses dados por meio do uso de “cookies” e outras tecnologias de
        rastreamento (veja abaixo). Quando aplicável, endereços IP explícitos,
        esses dados são anonimizados para garantir sua privacidade enquanto nos
        fornecem informações valiosas que nos ajudam a desenvolver nossos
        Serviços.
      </p>
      <h4 className="text-wrapper-22 text-bold">Cookies</h4>
      <p className="text-wrapper-22">
        Wellny usa cookies e tecnologias de rastreamento para fornecer
        funcionalidade e reconhecê-lo em diferentes Serviços e dispositivos.
        Para obter mais informações, consulte nosso Aviso de cookies e
        rastreamento, que inclui informações sobre como controlar ou desativar
        alguns desses cookies e tecnologias de rastreamento. Também podemos
        trabalhar com parceiros terceirizados que empregam tecnologias de
        rastreamento.
      </p>
      <p className="text-wrapper-22">
        Se você preferir não aceitar cookies ou desejar desativar nosso uso de
        tecnologias de rastreamento, isso pode ser escolhido inicialmente ao
        usar nosso site ou Serviços pela primeira vez; para mais alterações,
        consulte o Aviso de cookies e rastreamento. Observe que, se você
        desativar alguns dos cookies, isso poderá afetar negativamente sua
        experiência ao usar os Serviços, pois alguns recursos podem não
        funcionar corretamente.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Como usamos as informações que coletamos
      </h4>
      <p className="text-wrapper-22">
        Usamos as informações que coletamos, incluindo informações de
        identificação pessoal, para diversos fins, e como as usamos depende do
        que coletamos e de quais Serviços você usa (ou recursos dos Serviços).
        Esses propósitos podem incluir:
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Para fornecer os Serviços e personalizar sua experiência
      </h4>
      <p className="text-wrapper-22">
        Usamos as informações que coletamos, incluindo informações de
        identificação pessoal, para diversos fins, e como as usamos depende do
        que coletamos e de quais Serviços você usa (ou recursos dos Serviços).
        Esses propósitos podem incluir:
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Para pesquisa e desenvolvimento
      </h4>
      <p className="text-wrapper-22">
        Estamos sempre procurando maneiras de tornar nossos Serviços mais
        inteligentes, rápidos, seguros, integrados e úteis para você. Usamos
        coletivamente o aprendizado com o uso de nossos Serviços e o feedback
        fornecido diretamente para solucionar problemas e identificar
        tendências, uso, padrões de atividade e áreas de integração e melhoria
        dos Serviços.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Para nos comunicarmos com você sobre os Serviços
      </h4>
      <p className="text-wrapper-22">
        Podemos usar suas informações de contato para:
      </p>
      <ul className="text-wrapper-22">
        <li>
          Enviar comunicações transacionais por e-mail e dentro dos Serviços,
          inclusive confirmando suas compras; lembrá-lo de expirações de
          assinatura; responder aos seus comentários, perguntas e solicitações;
          fornecer suporte ao cliente e enviar avisos técnicos, atualizações,
          alertas de segurança e mensagens administrativas.
        </li>
        <li>
          Forneça comunicações personalizadas com base em sua atividade e
          interações conosco. Por exemplo, certas ações que você realiza nos
          Serviços podem acionar automaticamente um recurso ou sugestão de
          aplicativo de terceiros nos Serviços que facilitaria essa tarefa.
        </li>
        <li>
          Também lhe enviamos comunicações à medida que você se integra a um
          Serviço específico para ajudá-lo a se tornar mais proficiente no uso
          desse Serviço.
        </li>
      </ul>
      <p className="text-wrapper-22">
        Essas comunicações fazem parte dos Serviços e, em alguns casos, você não
        pode desativá-las, pois elas são necessárias para que possamos fornecer
        o Serviço. Se uma opção de exclusão estiver disponível, você encontrará
        essa opção na própria comunicação.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Para comercializar, promover e impulsionar o engajamento com os Serviços
      </h4>
      <p className="text-wrapper-22">
        Podemos usar suas informações de contato e informações sobre como você
        usa os Serviços para enviar comunicações promocionais que possam ser de
        seu interesse específico, inclusive por e-mail e exibindo anúncios
        Wellny em sites e aplicativos de outras empresas, bem como em
        plataformas como Facebook e Google.
      </p>
      <p className="text-wrapper-22">
        Essas comunicações visam impulsionar o engajamento e maximizar o que
        você obtém dos Serviços, incluindo informações sobre novos recursos,
        solicitações de pesquisas, boletins informativos, serviços
        complementares de terceiros e eventos que acreditamos ser de seu
        interesse. As preferências em relação ao recebimento de tais
        comunicações podem ser ajustadas nos respectivos links incluídos nas
        comunicações, e estão descritas na seção "Opt-out de comunicações"
        abaixo.
      </p>
      <h4 className="text-wrapper-22 text-bold">Suporte ao cliente</h4>
      <p className="text-wrapper-22">
        Podemos usar suas informações para resolver problemas técnicos que você
        encontrar, responder às suas solicitações de assistência, analisar
        informações sobre falhas ou reparar e melhorar os Serviços.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Para segurança e proteção
      </h4>
      <p className="text-wrapper-22">
        Usamos informações sobre você e seu uso do Serviço para verificar contas
        e atividades, monitorar atividades suspeitas ou fraudulentas e
        identificar violações das políticas do Serviço.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Para cumprir a lei aplicável
      </h4>
      <p className="text-wrapper-22">
        Podemos usar suas informações conforme necessário para cumprir a lei
        aplicável, incluindo solicitações governamentais, solicitações de
        aplicação da lei e de outra forma para proteger os direitos,
        privacidade, segurança ou propriedade sua, nossa ou de outros.
      </p>
      <h4 className="text-wrapper-22 text-bold">Com seu consentimento</h4>
      <p className="text-wrapper-22">
        Podemos usar informações sobre você quando você nos deu consentimento
        para isso para uma finalidade específica não listada acima. Por exemplo,
        podemos publicar depoimentos ou histórias de clientes em destaque para
        promover os Serviços, com sua permissão.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Desativação de comunicações
      </h4>
      <p className="text-wrapper-22">
        Se você não quiser mais receber informações por meio de nosso boletim
        informativo e e-mails de suporte que lhe enviamos, você pode alterar
        suas preferências nos links incluídos nessas comunicações. Mais detalhes
        sobre seus direitos relacionados a você e suas informações podem ser
        encontrados na seção "Como acessar e gerenciar seus dados"
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Como compartilhamos as informações que coletamos
      </h4>
      <p className="text-wrapper-22">
        Wellny não compartilha ou vende informações financeiras ou descritivas
        identificáveis de você para terceiros. Podemos compartilhar esse tipo de
        informação exclusivamente por meio de técnicas de agregação e
        anonimização, o que impede que as informações sejam reassociadas ou
        identificadas com qualquer conta, empresa ou indivíduo. Essas
        informações podem ser usadas para uma ampla variedade de propósitos
        estatísticos e analíticos.
      </p>
      <p className="text-wrapper-22">
        Para oferecer nossos Serviços e o suporte que às vezes você precisa ao
        usá-los, contratamos terceiros para realizar importantes aspectos
        periféricos de nosso Serviço.
      </p>
      <p className="text-wrapper-22">
        Usamos a Intercom para poder nos comunicar através do chat online, eles
        têm seus próprios cookies e tipos de informações que armazenam para
        poder nos transmitir as informações relevantes para melhor atendê-lo.
        Você pode encontrar a política de privacidade deles aqui.
      </p>
      <p className="text-wrapper-22">
        Usamos o SendGrid para poder nos comunicar com todos os nossos usuários,
        através do boletim informativo, atualizações e desenvolvimentos do
        Serviço e oferta, bem como outras notícias relevantes ou material
        promocional. Você pode encontrar a política de privacidade deles aqui.
      </p>
      <p className="text-wrapper-22">
        Outros terceiros com os quais compartilhamos informações incluem Google
        Analytics e Adwords. Com eles, compartilhamos apenas informações não
        pessoalmente identificáveis e cuja finalidade nos ajuda a melhorar
        nossos Serviços. Além disso, empregamos o uso de terceiros para
        processar seus pagamentos, conforme explicado na seção Informações de
        pagamento.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Outros cenários em que podemos compartilhar suas informações
      </h4>
      <p className="text-wrapper-22">
        Existem certas situações em que podemos ter que compartilhar suas
        informações com terceiros. Isso pode ocorrer no caso de uma compra ou
        venda de ativos comerciais; nesse caso, podemos compartilhar informações
        com um potencial comprador ou vendedor; se formos obrigados a
        compartilhar informações para cumprir obrigações legais; para auxiliar
        ou conduzir investigações de fraude ou outra atividade ilegal quando
        julgarmos razoável fazê-lo; ou quaisquer outras circunstâncias não
        necessariamente previsíveis, mas com níveis suficientes de obrigação
        legal ou razão para fazê-lo
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Como armazenamos e protegemos as informações
      </h4>
      <p className="text-wrapper-22">
        A segurança de suas informações é de extrema importância para nós,
        portanto, usamos apenas terceiros em quem confiamos e são essenciais
        para fornecer nossos Serviços a você. Isso significa que não
        compartilhamos informações de identificação pessoal com outras partes
        sem o seu consentimento.
      </p>
      <p className="text-wrapper-22">
        Suas informações são armazenadas com segurança por trás de níveis de
        criptografia e segurança por meio do uso de métodos verificados,
        incluindo limitação de acesso, tecnologia de criptografia TLS (Transport
        Layer Security) padrão do setor para proteger informações confidenciais,
        criptografia de dados e firewalls. Armazenamos nossas informações em
        servidores localizados nos Estados Unidos da América. Não enviamos
        nenhuma informação para fora dos Estados Unidos da América, exceto
        quando necessário para fornecer nossos Serviços e processar suas
        solicitações.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Por quanto tempo armazenamos seus dados?
      </h4>
      <p className="text-wrapper-22">
        Embora os cookies que usamos possam expirar após um certo período de
        tempo, as informações da sua conta são armazenadas por nós
        indefinidamente, pois processos legais e regulatórios no futuro podem
        exigir essas informações. Naturalmente, este não é o caso se você
        desejar que excluamos todas as informações de identificação pessoal que
        mantemos sobre você, para obter mais informações sobre isso, consulte
        "Como acessar e gerenciar seus dados".
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Como acessar e gerenciar seus dados
      </h4>
      <p className="text-wrapper-22">
        Em primeiro lugar, você pode escolher quais informações são coletadas de
        você automaticamente por meio do uso de cookies. Alguns cookies são
        necessários para fornecermos um site funcional, enquanto outros que nos
        ajudam a melhorar nosso serviço e sua experiência podem ser desativados;
        para obter mais informações, consulte nosso Aviso de cookies e
        rastreamento. Consulte também esta página se desejar alterar suas
        preferências de cookies.
      </p>
      <p className="text-wrapper-22">
        Em última análise, você é o proprietário de suas informações e,
        portanto, tem controle sobre elas. Isso significa que, se desejar, você
        pode:
      </p>
      <ul className="text-wrapper-22">
        <li>
          solicite para ver todas as informações que temos relevantes para sua
          pesso
        </li>
        <li>corrigir qualquer informação que tenhamos que esteja incorreta</li>
        <li>excluir todas as informações relacionadas a você</li>
        <li>
          transfira seus dados para outro controlador de dados, por exemplo,
          outra plataforma online
        </li>
        <li>
          para quaisquer perguntas ou dúvidas sobre suas informações,
          escreva-nos para privacy@wellny.com.br Enviar e-mail agora
        </li>
      </ul>
      <h4 className="text-wrapper-22 text-bold">
        Outras informações de privacidade
      </h4>
      <h4 className="text-wrapper-22 text-bold">
        Nossa política para crianças
      </h4>
      <p className="text-wrapper-22">
        Os Sites ou nossos Serviços não se destinam ou são direcionados a
        crianças menores de 16 anos, e não coletamos intencionalmente ou
        intencionalmente informações sobre crianças menores de 16 anos. Se você
        acredita que coletamos informações sobre uma criança menor de 16 anos,
        entre em contato conosco em support@wellny.com.br Enviar e-mail agora,
        para que possamos excluir imediatamente as informações.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        Alterações nesta Política de Privacidade
      </h4>
      <p className="text-wrapper-22">
        Qualquer informação que coletamos está sujeita à Política de Privacidade
        em vigor no momento em que essas informações são coletada
      </p>
      <p className="text-wrapper-22">
        Podemos alterar esta política de privacidade de tempos em tempos.
        Publicaremos quaisquer alterações na política de privacidade nesta
        página e, se as alterações forem significativas, forneceremos um aviso
        mais proeminente adicionando um aviso nas páginas iniciais dos Serviços,
        telas de login ou enviando uma notificação por e-mail. Também manteremos
        as versões anteriores desta Política de Privacidade em um arquivo para
        sua revisão. Recomendamos que você revise nossa política de privacidade
        sempre que usar os Serviços para se manter informado sobre nossas
        práticas de informações e as maneiras pelas quais você pode ajudar a
        proteger sua privacidade.
      </p>
      <p className="text-wrapper-22">
        Se você discordar de quaisquer alterações a esta política de
        privacidade, será necessário parar de usar os Serviços, incluindo nossos
        sites, e desativar sua(s) conta(s), conforme descrito acima.
      </p>
      <h4 className="text-wrapper-22 text-bold">Contatos</h4>
      <p className="text-wrapper-22">
        Se você tiver alguma dúvida sobre esta Política de Privacidade ou nosso
        tratamento das informações que você nos fornece, entre em contato
        conosco em support@wellny.com.br ou Enviar e-mail agora
      </p>
    </div>
  );
};
