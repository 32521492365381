// PlanCard.tsx
// import "./styles.css";
import React from 'react';
import { BulletCheck7 } from '../../icons/BulletCheck7';
import { Button } from '../Button';

interface PlanCardProps {
    title: string;
    alert: string;
    price: number;
    features: string[];
    isHighlighted?: boolean;
}

export const PlanCard: React.FC<PlanCardProps> = ({ title, price, alert, features, isHighlighted = false }) => {
    return (
        <div className={`plan-card ${isHighlighted ? 'highlighted' : ''}`}>
            <div className="price-section">
                <h3 className="title" style={{ color: '#2a2828' }}>{title}</h3>
                <div className="price">
                    <span>R$</span>
                    <span className="price-value">{price}</span>
                </div>
                <p className="price-subtext">Pagamento mensal</p>
                <p className="price-subtext" style={{ marginTop: 12, color: '#282727', minHeight: 100 }}>{alert}</p>
            </div>
            <div className="divider" />
            <a href="https://app.wellny.com.br/login?createAccount=true" target="_blank" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Button
                    text="Iniciar Teste Gratuito"
                    status={"primary"}
                    icon={"dir"}
                    color={"on"}
                    className={undefined}
                />
            </a>
            <div className="divider" />
            <div className="features" style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 12 }}>
                <p className="access-text">Você tem acesso à:</p>
                {features.map((feature, index) => (
                    <div className="feature" key={index}>
                        <BulletCheck7 className="bullet-icon" />
                        <p style={{ textAlign: 'left', flexWrap: 'wrap', maxWidth: '90%' }}>{feature}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};