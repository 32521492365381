import React, { useEffect, useRef } from "react";
import { Menu } from "../Menu";
import { Footer } from "../Footer";

interface LayoutMainProps {
  children: React.ReactNode;
}

const LayoutMain = ({ children }: LayoutMainProps) => {
  return (
    <>
      <Menu />
      <main className="landing-page">
        {children}
        <Footer />
      </main>
    </>
  );
};

export default LayoutMain;
