import React from "react";

export const AppReferences = (): JSX.Element => {
  return (
    <div className="container-page container-privacy-terms privacy-terms-text">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginBottom: 24 }}>
        <div className="text-wrapper-16">
          Olá! Estamos felizes em vê-lo por aqui, buscando bem-estar!
        </div>
        <div className="text-wrapper-16" style={{ fontSize: 16, color: "#646464" }}>
          Na Wellny, combinamos paixão por tecnologia com uma abordagem holística da saúde para promover o cuidado preventivo. Criamos uma metodologia proprietária, fundamentada em fontes confiáveis, para oferecer recomendações personalizadas baseadas em seus hábitos e outros aspectos relevantes da sua saúde. Com nosso sistema intuitivo, fica fácil monitorar seu bem-estar e ajustar seus objetivos de saúde, incentivando você a buscar apoio profissional quando necessário.
        </div>
      </div>

      <div className="text-wrapper-16">Referências para alimentação</div>
      <p className="text-wrapper-22">
        <a href="https://www.who.int/news-room/fact-sheets/detail/healthy-diet">Healthy Diets by WHO</a>
      </p>
      <p className="text-wrapper-22">
        <a href="https://www.who.int/europe/news-room/fact-sheets/item/a-healthy-lifestyle---who-recommendations">A healthy lifestyle - WHO recommendations</a>
      </p>
      <p className="text-wrapper-22">
        <a href="https://www.cambridge.org/core/journals/british-journal-of-nutrition/article/mediterranean-diet-health-science-and-society/2AD00DAC00E6C5AA6139F81A9D12A37C">The Mediterranean diet: health, science and society</a>
      </p>

      <div className="text-wrapper-16">Índice de massa corporal (IMC)</div>
      <p className="text-wrapper-22">
        <a href="https://www.nhlbi.nih.gov/health/educational/lose_wt/BMI/bmicalc.htm">IMC Calculator - NHLBI</a>
      </p>
      <p className="text-wrapper-22">
        <a href="https://nutritionsource.hsph.harvard.edu/healthy-weight/measuring-fat/">Relação estatura altura e IMC</a>
      </p>

      <div className="text-wrapper-16">Taxa metabólica basal</div>
      <p className="text-wrapper-22">
        Frankenfield, D., Roth-Yousey, L., & Compher, C. (2005). Comparison of predictive equations for resting metabolic rate in healthy nonobese and obese adults: a systematic review. Journal of the American Dietetic Association, 105(5), 775-789.
      </p>

      <div className="text-wrapper-16">Referências para sono</div>
      <p className="text-wrapper-22">
        Schwartz WJ, Klerman EB. Circadian Neurobiology and the Physiologic Regulation of Sleep and Wakefulness. Neurol Clin. 2019;37(3):475–86.
      </p>
      <p className="text-wrapper-22">
        Morin, C., Drake, C., Harvey, A. et al. Insomnia disorder. Nat Rev Dis Primers 1, 15026 (2015). <a href="https://doi.org/10.1038/nrdp.2015.26">https://doi.org/10.1038/nrdp.2015.26</a>
      </p>
      <p className="text-wrapper-22">
        <a href="https://www.sleepfoundation.org/">Sleep Foundation</a>
      </p>

      <div className="text-wrapper-16">Referências para atividade física</div>
      <p className="text-wrapper-22">
        <a href="https://iris.who.int/bitstream/handle/10665/337001/9789240014886-por.pdf">Diretrizes da World Health Organization para Atividade Física e Comportamento Sedentárrio</a>
      </p>
      <p className="text-wrapper-22">
        Craig, C. L., Marshall, A. L., Sjöström, M., Bauman, A. E., Booth, M. L., Ainsworth, B. E., ... & Oja, P. (2003). International physical activity questionnaire: 12-country reliability and validity. Medicine & Science in Sports & Exercise, 35(8), 1381-1395.
      </p>

      <div className="text-wrapper-16">Referências para stress e trabalho</div>
      <p className="text-wrapper-22">
        <a href="https://www.who.int/publications/i/item/9789240003927">Doing What Matters in Times of Stress by WHO</a>
      </p>
      <p className="text-wrapper-22">
        Karasek, R. A., & Theorell, T. (1990). Healthy Work: Stress, Productivity, and the Reconstruction of Working Life. Basic Books.
      </p>
      <p className="text-wrapper-22">
        Schulz, P., Schlotz, W., & Becker, P. (2004). Trierer Inventar zum chronischen Stress (TICS). Göttingen: Hogrefe.
      </p>
      <p className="text-wrapper-22">
        Folkman, S., & Lazarus, R. S. (1985). If it changes it must be a process: Study of emotion and coping during three stages of a college examination. Journal of Personality and Social Psychology, 48(1), 150-170.
      </p>

      <div className="text-wrapper-16">Referências para dor</div>
      <p className="text-wrapper-22">
        Hawker, G. A., Mian, S., Kendzerska, T., & French, M. (2011). Measures of adult pain: Visual Analog Scale for Pain (VAS Pain), Numeric Rating Scale for Pain (NRS Pain), McGill Pain Questionnaire (MPQ), Short-Form McGill Pain Questionnaire (SF-MPQ), Chronic Pain Grade Scale (CPGS), Short Form-36 Bodily Pain Scale (SF-36 BPS), and Measure of Intermittent and Constant Osteoarthritis Pain (ICOAP). Arthritis Care & Research, 63(S11), S240-S252.
      </p>
      <p className="text-wrapper-22">
        Fairbank, J. C., & Pynsent, P. B. (2000). The Oswestry Disability Index. Spine, 25(22), 2940-2953.
      </p>
      <p className="text-wrapper-22">
        Cleeland, C. S., & Ryan, K. M. (1994). Pain assessment: global use of the Brief Pain Inventory. Annals of the Academy of Medicine, Singapore, 23(2), 129-138.
      </p>
    </div>
  );
};