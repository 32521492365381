import React, { useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

interface MenuItemProps {
  text: string;
  href?: string;
  onClick: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ text, onClick, href }) => {
  const route = useNavigate()

  const GoTo = () => {
    if (href && onClick) {
      route(href)
    }
  }

  return (
    <div className="text-wrapper-15" onClick={() => {
      if (href) {
        GoTo()
      } else {
        onClick()
      }
    }}>
      {
        href && (
          <a href={href}></a>
        )
      }
      {text}
    </div>
  );
};
