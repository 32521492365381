import React from "react";
export const TermsOfUse = (): JSX.Element => {
  return (
    <div className="container-page container-privacy-terms privacy-terms-text">
      <h2 className="text-wrapper-16">Termos e Condições de Uso</h2>
      <p className="text-wrapper-22">
        Ao acessar ou utilizar nossos serviços, você concorda com estes termos,
        então por favor, leia-os cuidadosamente. Tentamos torná-los o mais
        claros e amigáveis possível, mantendo o juridiquês ao mínimo. Lembre-se,
        estamos aqui para ajudar se você tiver alguma dúvida!
      </p>
      <p className="text-wrapper-22">
        Seu acesso e uso dos Serviços Wellny, fornecidos pela Wellny Corp.
        (doravante referida como “Wellny”), são limitados pelos seguintes Termos
        e Condições.
      </p>
      <h2 className="text-wrapper-22 text-bold">Aceitação dos Termos</h2>
      <p className="text-wrapper-22">
        Ao usar os serviços de Wellny localizados em nossa sede ou por meio de
        nossas plataformas digitais, você está celebrando um acordo legal
        conosco, a Wellny Ltda. (Wellny), com base nestes Termos de Serviço
        (Termos). Para desfrutar de nossos serviços, a aceitação destes Termos é
        essencial. Podemos modificar estes Termos ocasionalmente, e faremos o
        nosso melhor para notificá-lo, mas é sempre uma boa ideia verificar aqui
        as últimas atualizações.
      </p>
      <h2 className="text-wrapper-22 text-bold">1. Nosso Acordo</h2>
      <p className="text-wrapper-22">
        Este documento, juntamente com nossa Política de Privacidade, delineia
        nosso acordo completo. Estamos comprometidos com a transparência, então
        tudo o que você precisa saber sobre o uso de nossos serviços está bem
        aqui!
      </p>
      <p className="text-wrapper-22">
        <strong>1.1</strong>  Os seguintes Termos e Condições aplicam-se ao
        acesso e uso dos Serviços de Wellny e, juntamente com a Política de
        Privacidade, constituem o acordo completo entre você e Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>1.2</strong>  O site de Wellny e a Plataforma Wellny serão
        conjuntamente referidos como “Wellny.com.br”.
      </p>
      <p className="text-wrapper-22">
        <strong>1.3</strong>  Ao usar a ferramenta online em Wellny.com.br, você
        concorda em estar vinculado pelos Termos e Condições estabelecidos aqui
        (os “Termos”). Certifique-se de ter lido e entendido os Termos antes de
        usar a ferramenta online. Se você não concordar com os Termos, não está
        autorizado a utilizar nenhum dos Serviços de Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>1.4</strong>  Reservamo-nos o direito de alterar os Termos de
        tempos em tempos sem aviso prévio. Se uma alteração ocorrer,
        notificaremos o usuário, e uma janela modal aparecerá para que aceitem
        os novos termos. A data da atualização será exibida nesta janela modal,
        e a versão mais recente de cada documento será indicada pela data de sua
        última revisão.
      </p>
      <p className="text-wrapper-22">
        <strong>1.4</strong>  Reservamo-nos o direito de alterar os Termos de
        tempos em tempos sem aviso prévio. Se uma alteração ocorrer,
        notificaremos o usuário, e uma janela modal aparecerá para que aceitem
        os novos termos. A data da atualização será exibida nesta janela modal,
        e a versão mais recente de cada documento será indicada pela data de sua
        última revisão.
      </p>
      <h4 className="text-wrapper-22 text-bold">2. Definições</h4>
      <p className="text-wrapper-22">
        <strong>2.1</strong>  Acordo significa o acordo que consiste nestes
        Termos e na Política de Privacidade, concluído entre você e Wellny, e
        que rege seu acesso e uso dos Serviços de Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>2.2</strong> Visitante significa qualquer pessoa que visite
        Wellny.com.br e que possa fazer uso dos Serviços de Wellny sem se tornar
        um Usuário Registrado.
      </p>
      <p className="text-wrapper-22">
        <strong>2.3</strong> Usuário Registrado é alguém que criou uma conta
        conosco.
      </p>
      <p className="text-wrapper-22">
        <strong>2.4</strong> Site de Wellny significa a página inicial de
        Wellny.com.br e todas as páginas web relacionadas pelas quais um
        visitante pode acessar a Plataforma Wellny e os Serviços.
      </p>
      <p className="text-wrapper-22">
        <strong>2.5</strong> A Plataforma Wellny é o lar de nossos serviços,
        acessível tanto para Usuários quanto para Usuários Registrados.
        Refere-se ao conjunto de ferramentas e ao ambiente que a Wellny
        disponibiliza para os Usuários Registrados.
      </p>
      <p className="text-wrapper-22">
        <strong>2.6</strong> Serviços da Plataforma refere-se a tudo que
        oferecemos na Wellny. Significa os serviços pagos fornecidos pela Wellny
        a Usuários Registrados mediante pagamento por tais serviços, consistindo
        no uso de e acesso à plataforma Wellny, manutenção, atualizações e
        suporte no uso da plataforma Wellny, acesso a atualizações de manutenção
        e suporte no uso dos módulos disponíveis.
      </p>
      <p className="text-wrapper-22">
        <strong>2.7</strong> Módulos significam as páginas na Plataforma Wellny
        que processam dados fornecidos pelo Usuário Registrado para atendimento
        à pacientes cadastrados pelo Usuário Registrado por meio da Plataforma
        Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>2.7.1</strong> Metodologia & Fluxo de Trabalho Wellny significam
        a tecnologia proprietária usada pela Wellny para guiar o Profissional o
        qual é um Usuário Registrado para atender a um paciente a fim de
        proporcionar uma melhor avaliação do paciente;
      </p>
      <p className="text-wrapper-22">
        <strong>2.7.2</strong> Dados do Visitante/Usuário Registrado significam
        todas as informações (no sentido mais amplo, incluindo tanto dados
        identificáveis quanto anônimos) fornecidas pelo visitante ou pelo
        Usuário Registrado na Plataforma Wellny e necessárias para a realização
        da avaliação do paciente.
      </p>
      <p className="text-wrapper-22">
        <strong>2.8</strong> Dados Pessoais significam todas as informações (no
        sentido mais amplo) necessárias pelos visitantes para se tornarem
        Usuários Registrados e submetidas por eles à Wellny por meio do
        formulário de registro online.
      </p>
      <p className="text-wrapper-22">
        <strong>2.9</strong> Taxa de Serviço é o que Usuários Registrados pagam
        para acessar nossos recursos.
      </p>
      <h4 className="text-wrapper-22 text-bold">3. Usando Wellny</h4>
      <p className="text-wrapper-22">
        <strong>3.1</strong> Acesso: Concedemos a você um direito não exclusivo,
        não sublicenciável e não transferível de acessar nossos serviços, desde
        que você siga estes Termos.
      </p>
      <p className="text-wrapper-22">
        <strong>3.2</strong> Para Seus Olhos Apenas: Os serviços são para seu
        uso pessoal. Sem revenda ou compartilhamento não autorizado.
      </p>
      <p className="text-wrapper-22">
        <strong>3.3</strong> Usuários Registrados têm o direito de comprar os
        Serviços da Plataforma oferecidos pela Wellny e não têm o direito de
        acessar a Plataforma Wellny de outra forma.
      </p>
      <p className="text-wrapper-22">
        <strong>3.4</strong> Os Serviços de Wellny disponibilizados para você
        como um Usuário Registrado são fornecidos exclusivamente para seu
        próprio uso. Você não deve vender, transferir, sublicenciar, distribuir,
        explorar comercialmente ou de outra forma disponibilizar para ou usar em
        benefício de terceiros quaisquer dos Serviços. Você não pode incluir os
        Serviços em qualquer produto ou serviço que você venda.
      </p>
      <p className="text-wrapper-22">
        <strong>3.5</strong> Proibição de Replicação: Pedimos que evite replicar
        ou usar nossos serviços para atividades competitivas.
      </p>
      <p className="text-wrapper-22">
        <strong>3.6</strong> Respeito aos Limites: Definimos regras estritas
        para garantir o uso correto de nossos serviços. É proibido envolver-se
        em hacking, scraping ou qualquer outra prática inapropriada.
      </p>
      <p className="text-wrapper-22">
        <strong>3.7</strong> A Wellny não garante a entrega completa, precisa e
        pontual dos Serviços em todos os momentos, uma vez que deficiências na
        entrega dos Serviços podem ocorrer parcialmente, mas não exclusivamente,
        como resultado de falhas fora do controle da Wellny, tais como, mas não
        limitado a falhas na conexão de internet. A Wellny não pode ser
        responsabilizada com relação ao hardware, software, periféricos e
        conexões de telecomunicação necessários para acessar e fazer uso dos
        Serviços, a menos que acordado de outra forma por escrito entre você e a
        Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>3.8</strong> Mantenha Seguro: A Wellny não pode ser
        responsabilizada com relação ao hardware, software, periféricos e
        conexões de telecomunicação necessários.
      </p>
      <p className="text-wrapper-22">
        <strong>3.9</strong> A Wellny tem o direito de fazer alterações nos
        Serviços a qualquer momento. Se uma mudança for razoavelmente prevista
        para ter consequências significativas para você, a Wellny fará o melhor
        esforço para notificá-lo com antecedência. Se você continuar a usar os
        Serviços, indica seu consentimento com as alterações feitas nos
        Serviços.
      </p>
      <h4 className="text-wrapper-22 text-bold">4. Configuração da Conta</h4>
      <p className="text-wrapper-22">
        Para se tornar um Usuário Registrado, é fácil: complete nosso formulário
        de inscrição com seus dados pessoais. Valorizamos conexões genuínas,
        portanto, o uso de bots é proibido.
      </p>
      <p className="text-wrapper-22">
        <strong>4.1</strong> Um visitante do Wellny.com.br é anônimo e pode
        opcionalmente criar uma conta Wellny e se tornar um Usuário Registrado,
        submetendo as informações necessárias no formulário de registro de conta
        online.
      </p>
      <p className="text-wrapper-22">
        <strong>4.2</strong> Você deve fornecer seus próprios dados pessoais
        durante o processo de registro da Conta Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>4.4</strong> Você é responsável a todo momento pela manutenção
        da confidencialidade das suas credenciais de login Wellny. A Wellny não
        pode ser responsabilizada nesse aspecto.
      </p>
      <p className="text-wrapper-22">
        <strong>4.5</strong> Você é responsável por todas as atividades
        realizadas sob e conteúdo colocado dentro de sua conta Wellny.
      </p>
      <h4 className="text-wrapper-22 text-bold">5. Serviços da Plataforma</h4>
      <p className="text-wrapper-22">
        Nossos serviços vêm com uma taxa para Usuários Registrados detalhada em
        sua conta. Mudanças nas taxas serão comunicadas e nos esforçamos por
        transparência em todas as nossas transações.{" "}
      </p>
      <p className="text-wrapper-22">
        <strong>5.1</strong> Os serviços da plataforma Wellny estão disponíveis
        exclusivamente para Usuários Registrados mediante o pagamento de uma
        Taxa de Serviço.
      </p>
      <p className="text-wrapper-22">
        <strong>5.2</strong> A Taxa de Serviço é recorrente e mensal, a ser paga
        antes do acesso aos serviços da plataforma Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>5.2.1</strong> A Taxa de Avaliação Wellny tem validade de 30
        dias para que Usuários Registrados conheçam a plataforma antes de
        comprar algum plano disponível.
      </p>
      <p className="text-wrapper-22">
        <strong>5.2.2</strong> A Taxa de Avaliação Wellny tem as seguintes
        limitações de avaliação: 1) impossibilidade de criar mais de um
        paciente; 2) impossibilidade de criar mais de um atendimento ao
        paciente.
      </p>
      <p className="text-wrapper-22">
        <strong>5.3</strong> Para ter acesso aos Serviços da Plataforma Wellny,
        o Usuário Registrado deve estar dentro do período de validade de 30 dias
        da Taxa de Avaliação Wellny e dentro dos dias específicos comprados com
        a Taxa de Avaliação Wellny, ou o Usuário Registrado deve ter um Plano de
        Assinatura da Plataforma Wellny válido.
      </p>
      <p className="text-wrapper-22">
        <strong>5.4</strong> Se os dias comprados com a Taxa de Avaliação Wellny
        terminarem, é possível adquirir um plano pago.
      </p>
      <p className="text-wrapper-22">
        <strong>5.5</strong> e um Usuário Registrado acessar a Wellny em um dia
        fora dos dias comprados com a Taxa de Acesso, ele/ela não terá acesso
        aos Serviços Regulares da Plataforma Wellny.
      </p>
      <h4 className="text-wrapper-22 text-bold">6. Assinatura da Plataforma Wellny</h4>
      <p className="text-wrapper-22">
        <strong>6.1</strong> A Assinatura da Plataforma Wellny é um serviço
        baseado em assinatura de acesso mensal oferecido pela Wellny,
        independentemente do plano.
      </p>
      <p className="text-wrapper-22">
        <strong>6.2</strong> Quando você enviou uma confirmação de pedido e fez
        o pagamento do Plano de Assinatura da Plataforma Wellny, você entrou em
        um acordo de assinatura com a Wellny por um período ilimitado, pois ele
        se renova mensalmente com a capacidade de cancelar antes do final do
        período de acesso a qualquer momento.
      </p>
      <p className="text-wrapper-22">
        <strong>6.3</strong> O Plano de Assinatura da Plataforma Wellny se
        renova em uma base recorrente de 1 mês. Você pode cancelar sua renovação
        automática através da Gestão de Assinatura disponível na Plataforma
        Wellny.
      </p>
      <h4 className="text-wrapper-22 text-bold">7. Uso da plataforma WELLNY</h4>
      <p className="text-wrapper-22">
        Estamos comprometidos em manter uma plataforma segura, respeitosa e em
        conformidade com a lei. Ao juntar-se a nós, você concorda em usar nossos
        serviços de maneira responsável e ética.
      </p>
      <p className="text-wrapper-22">
        <strong>7.1</strong> Um Usuário Registrado pode usar a Plataforma Wellny
        e os Serviços da Plataforma para mais de uma unidade de atendimento,
        porém as quantidades são limitadas conforme o plano comprado.
      </p>
      <p className="text-wrapper-22">
        <strong>7.2</strong> Todas as taxas de pagamento mencionadas nas
        cláusulas 5 e 6, relacionadas ao acesso e uso da Taxa de Avaliação
        Wellny e dos Serviços de Assinatura da Plataforma Wellny, são aplicadas
        por unidade de atendimento, atendimentos realizados e usuários de
        acesso.
      </p>
      <h4 className="text-wrapper-22 text-bold">8. Termos de Pagamento e Compromisso</h4>
      <p className="text-wrapper-22">
        <strong>8.1</strong> Pagamentos por quaisquer Serviços da Plataforma
        Wellny são realizados antes do acesso a tais serviços.
      </p>
      <p className="text-wrapper-22">
        <strong>8.2</strong> O processamento de todos os pagamentos pelos
        Serviços da Plataforma Wellny é realizado em nome da Wellny por um
        provedor de serviços de pagamento online.
      </p>
      <p className="text-wrapper-22">
        <strong>8.3</strong> Você pode baixar as faturas de todos os seus
        pagamentos de assinatura através da seção de Gestão de Assinaturas ba
        Plataforma Wellny. As faturas também serão fornecidas por e-mail através
        do provedor de serviços de pagamento.
      </p>
      <p className="text-wrapper-22">
        <strong>8.4</strong> Se, por qualquer motivo, um pagamento do Plano de
        Serviços Wellny ou Plano de Assinatura Wellny for malsucedido, a Wellny
        reserva o direito de terminar sua assinatura, levando em consideração
        todos os fatos e circunstâncias. Se sua assinatura for terminada pela
        Wellny devido a um pagamento de assinatura mal-sucedido, a rescisão será
        comunicada a você por e-mail.
      </p>
      <p className="text-wrapper-22">
        <strong>8.5</strong> Os Serviços da Plataforma automaticamente deletarão
        todos os dados do Usuário Registrado e dados de organização configurados
        para contas inativas no dia 90.
      </p>
      <h4 className="text-wrapper-22 text-bold">9. Termos da Política de Reembolso</h4>
      <p className="text-wrapper-22">
        <strong>9.1</strong> Caso encontre os serviços fornecidos pela Wellny
        insatisfatórios ou encontre algum problema, você pode solicitar um
        reembolso pela sua compra. Isso deve ser feito dentro de um período de
        dez dias após a conclusão da transação, aderindo às diretrizes
        delineadas na seção 10. A elegibilidade para reembolso é contingente à
        ausência de qualquer atividade fraudulenta ou maliciosa detectada em sua
        conta Wellny e a uma base de boa fé para o pedido de reembolso. Pedidos
        de reembolso feitos após dez dias da compra inicial da assinatura serão
        avaliados para elegibilidade de reembolso com base nas estipulações
        delineadas nas seções subsequentes.
      </p>
      <p className="text-wrapper-22">
        <strong>9.2</strong> De acordo com a natureza especializada dos serviços
        fornecidos pela Wellny, é nossa prática padrão não emitir reembolsos por
        transações ocorridas dez (10) ou mais dias subsequentes à data da
        compra, exceto quando mandatado pelas regulamentações de proteção ao
        consumidor.
      </p>
      <p className="text-wrapper-22">
        <strong>9.3</strong> Para iniciar um reembolso, convidamos você a nos
        contatar através do email support@wellny.com.br. Após a submissão, seu
        pedido será revisado diligentemente e você receberá uma resposta sobre a
        aceitação ou negação do seu reembolso dentro de 72 horas.
      </p>
      <p className="text-wrapper-22">
        <strong>9.4</strong> A Wellny avaliará cada petição de reembolso com uma
        revisão cuidadosa do uso do serviço, quaisquer dificuldades
        experimentadas, a razão para a solicitação de reembolso e a completude
        dos detalhes fornecidos na aplicação de reembolso da Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>9.5</strong> Caso sua aplicação para reembolso seja concedida, é
        costume que o reembolso seja processado através do método original de
        pagamento utilizado no momento da transação.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        10. Modificações e Atualizações nos Serviços e Estrutura de Preços
      </h4>
      <p className="text-wrapper-22">
        A Wellny evolui, e nossos serviços também. Manteremos você informado
        sobre mudanças importantes, mas incentivamos você a permanecer engajado
        e informado.
      </p>
      <p className="text-wrapper-22">
        <strong>10.1</strong> A Wellny reserva-se o direito de implementar
        alterações no Site da Wellny e em quaisquer Serviços da Wellny
        fornecidos. Nestes casos, a Wellny fará o possível para notificá-lo de
        quaisquer modificações iminentes, esforçando-se para fornecer a você um
        aviso prévio adequado.
      </p>
      <p className="text-wrapper-22">
        <strong>10.2</strong> A Wellny tem a discrição de modificar os preços
        para o uso dos Serviços de Avaliação da Wellny ou Planos de Assinatura
        da Wellny. Notificações sobre tais revisões de preços podem ser
        comunicadas pelo menos um mês antes de entrarem em vigor.
      </p>
      <h4 className="text-wrapper-22 text-bold">11. Responsabilidades e Comportamento</h4>
      <p className="text-wrapper-22">
        <strong>11.1</strong> A Wellny assegura o armazenamento interno seguro
        de todos os dados cadastrados. Esses dados e materiais estão sob a
        gestão exclusiva da Wellny e serão utilizados no âmbito dos serviços e
        produtos fornecidos pela Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>11.2</strong> Ao fornecer seus serviços e produtos, a Wellny
        compromete-se a tratar os dados pessoais e corporativos com o máximo
        cuidado e em conformidade com a Lei de Proteção de Dados Pessoais, o que
        também se estende a quaisquer dados gerados pelo Serviço.
      </p>
      <p className="text-wrapper-22">
        <strong>11.3</strong> A Política de Privacidade detalhada na plataforma
        da Wellny estabelece os termos do processamento de dados pessoais pela
        WELLNY. Esta Política de Privacidade é uma parte vinculante dos Termos e
        Condições de Uso. Ao consentir com estes Termos, você afirma
        simultaneamente sua aceitação da Política de Privacidade estipulada.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        12. Garantias e Limitações de Responsabilidade
      </h4>
      <p className="text-wrapper-22">
        A Wellny não é perfeita e, embora nos esforcemos para fornecer uma
        representação precisa do valor, não assumimos responsabilidade por
        eventuais erros nos formulários de atendimento ao paciente, exceto pelo
        reembolso do montante pago pelo assinante, que é o limite da nossa
        obrigação.
      </p>
      <p className="text-wrapper-22">
        <strong>12.1</strong> O Serviço da Wellny é projetado como uma
        ferramenta complementar, e não como a base primária para tomada de
        decisões, dependendo de dados originados de terceiros. Garantir a
        precisão desses dados está além da capacidade da Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>12.2</strong> Embora a Wellny se esforce para fornecer um
        serviço da mais alta qualidade e confiabilidade, o Serviço e os serviços
        associados de terceiros são oferecidos sem quaisquer garantias expressas
        ou implícitas quanto à sua condição ou funcionalidade.
      </p>
      <p className="text-wrapper-22">
        <strong>12.3</strong> A Wellny não oferece garantias ou seguranças
        quanto ao conteúdo do Serviço. Apesar dos esforços para assegurar a
        precisão e integridade do Serviço, este pode conter informações
        desatualizadas ou incorretas. Consequentemente, a utilização do Serviço
        é inteiramente a critério do usuário.
      </p>
      <p className="text-wrapper-22">
        <strong>12.4</strong> A Wellny expressamente isenta qualquer
        responsabilidade por perda ou dano, seja por contrato, negligência,
        violação de dever estatutário ou sob qualquer outra causa de ação
        relacionada ao uso do Serviço e quaisquer serviços de terceiros. Isso
        inclui, mas não se limita a, perdas indiretas ou consequenciais, bem
        como perdas financeiras específicas, como lucros, receitas, negócios ou
        goodwill, e perdas antecipadas ou incidentais, como economias esperadas,
        aumento esperado em dívidas ruins ou falha em mitigar dívidas ruins.
      </p>
      <p className="text-wrapper-22">
        <strong>12.5</strong> No caso de uma reivindicação legítima contra a
        Wellny, sua responsabilidade financeira será limitada a um valor
        equivalente aos pagamentos feitos pelos serviços prestados sob este
        acordo durante o ano em que a reivindicação foi feita.
      </p>
      <p className="text-wrapper-22">
        <strong>12.6</strong> Cada signatário deste Acordo assegura que obteve e
        manterá todas as licenças necessárias, consentimentos, permissões e
        acordos necessários para cumprir suas obrigações sob este Acordo e para
        permitir a alocação de direitos à outra parte conforme estipulado aqui.
      </p>
      <h4 className="text-wrapper-22 text-bold">13. Duração e Término do Acordo</h4>
      <p className="text-wrapper-22">
        Este contrato entra em vigor assim que você inicia o uso de nossos
        serviços. Almejamos um relacionamento duradouro e proveitoso, mas
        mantemos a prerrogativa de interromper ou encerrar seu acesso em caso de
        inconformidades, sempre adotando procedimentos justos.{" "}
      </p>
      <p className="text-wrapper-22">
        <strong>13.1</strong> Este Acordo torna-se efetivo após seu registro e
        criação de conta de Usuário Registrado Wellny por meio de qualquer uma
        das opções de criação de conta Wellny.com.br e permanecerá válido
        indefinidamente.
      </p>
      <p className="text-wrapper-22">
        <strong>13.2</strong> Durante a duração deste Acordo, a Wellny pode
        entrar em contato para informá-lo sobre novas ofertas e aprimoramentos
        de produto.
      </p>
      <p className="text-wrapper-22">
        <strong>13.3</strong> A Wellny reserva o direito de implementar
        atualizações, alterações ou aprimoramentos em Wellny.com.br e nos
        Serviços da Plataforma Wellny. Nestes casos, a Wellny esforçar-se-á para
        fornecer a você aviso prévio suficiente dessas mudanças.
      </p>
      <p className="text-wrapper-22">
        <strong>13.4</strong> Você é obrigado a reembolsar a Wellny por
        quaisquer despesas de terceiros incorridas na coleta de pagamentos.
      </p>
      <p className="text-wrapper-22">
        <strong>13.5</strong> Caso a Wellny determine que você violou quaisquer
        termos deste Acordo ou, no caso de sua insolvência ou falência, a Wellny
        pode imediatamente suspender seu acesso aos Serviços e terminar este
        Acordo sem aviso prévio.
      </p>
      <p className="text-wrapper-22">
        <strong>13.6</strong> A Wellny tem o direito de terminar unilateralmente
        este Acordo com efeito imediato, sem qualquer obrigação de notificá-lo
        de inadimplência e/ou suspender suas obrigações e/ou permanentemente
        barrar seu acesso aos Serviços e/ou deletar qualquer conteúdo postado
        por você em Wellny.com.br ou restringir seu acesso a ele sem aviso
        prévio se:
      </p>
      <p className="text-wrapper-22">
        <strong>13.6.1</strong> Você negligenciar em cumprir suas
        responsabilidades sob o Acordo e/ou os Termos, parcial ou totalmente;
      </p>
      <p className="text-wrapper-22">
        <strong>13.6.2</strong> Após a conclusão do Acordo, a Wellny descobrir
        circunstâncias que lhe dão motivos razoáveis para duvidar de sua
        capacidade de cumprir suas obrigações contratuais;
      </p>
      <p className="text-wrapper-22">
        <strong>13.6.3</strong> Situações surgirem que tornem o cumprimento do
        Acordo inviável para a Wellny;
      </p>
      <p className="text-wrapper-22">
        <strong>13.6.4</strong> A Wellny for informada de sua participação na
        publicação de conteúdo em Wellny.com.br que seja considerado
        discriminatório, racista, vingativo, ofensivo ou de outra forma
        repreensível;
      </p>
      <p className="text-wrapper-22">
        <strong>13.6.5</strong> A Wellny tomar conhecimento de sua participação
        na disseminação de conteúdo em Wellny.com.br que infrinja direitos de
        propriedade intelectual de terceiros;
      </p>
      <p className="text-wrapper-22">
        <strong>13.6.6</strong> Você tiver solicitado ou recebido uma suspensão
        de pagamentos.
      </p>
      <p className="text-wrapper-22">
        <strong>13.7</strong> Após o término, você é obrigado a remover
        diligentemente quaisquer cópias dos Serviços em sua posse em qualquer
        formato e cessar qualquer uso adicional dos Serviços.
      </p>
      <p className="text-wrapper-22">
        <strong>13.8</strong> Certas disposições, especificamente cláusulas 3,
        4, 11, 12, 14, 15, 16, 17 e 18, permanecerão em pleno vigor após a
        terminação deste Acordo.
      </p>
      <h4 className="text-wrapper-22 text-bold">14. Indenização</h4>
      <p className="text-wrapper-22">
        <strong>14.1</strong> Você concorda em indenizar, defender e isentar a
        WELLNY, bem como suas empresas controladoras, subsidiárias, afiliadas,
        diretores e funcionários, de todas as perdas, despesas, danos,
        reivindicações ou demandas, incluindo honorários advocatícios razoáveis,
        decorrentes de qualquer terceiro devido ou relacionado ao seu uso dos
        Serviços de maneira que viole este Acordo.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        15. Direitos de Propriedade Intelectual
      </h4>
      <p className="text-wrapper-22">
        O que criamos é protegido. Você é bem-vindo para usar nossos serviços
        conforme pretendido, mas por favor, respeite quaisquer direitos autorais
        e marcas registradas.
      </p>
      <p className="text-wrapper-22">
        <strong>15.1</strong> Os termos e condições apresentados neste documento
        governam seu acesso e uso dos Serviços Wellny e, junto com a Política de
        Privacidade, estabelecem o acordo completo entre você e a Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>15.2</strong> Coletivamente, o Site da Wellny e os Serviços da
        Plataforma Wellny, serão referidos como Wellny.com.br.
      </p>
      <p className="text-wrapper-22">
        <strong>15.3</strong> Ao utilizar a ferramenta online disponível em
        Wellny.com.br, você concorda em cumprir os Termos e Condições delineados
        neste documento (os Termos). É imperativo que você revise e compreenda
        os Termos antes de usar a ferramenta online. Caso encontre os Termos
        inaceptáveis, você é expressamente proibido de acessar ou usar quaisquer
        Serviços da Plataforma Wellny.
      </p>
      <p className="text-wrapper-22">
        <strong>15.4</strong> Reservamos o direito unilateral de modificar estes
        Termos a qualquer momento, sem qualquer obrigação de notificá-lo
        antecipadamente. Quaisquer revisões serão indicadas pela atualização da
        data na parte inferior dos Termos para refletir a data da última
        modificação.
      </p>
      <h4 className="text-wrapper-22 text-bold">16. Exceção devido a Força Maior</h4>
      <p className="text-wrapper-22">
        <strong>16.1</strong> A Wellny não será responsabilizada por qualquer
        falha em cumprir suas obrigações sob este Acordo devido a eventos ou
        condições imprevistos ou além do controle razoável da Wellny. Isso
        inclui, mas não se limita a, interrupções no serviço de internet, falhas
        de telecomunicações e mal funcionamentos em software ou hardware.
      </p>
      <h4 className="text-wrapper-22 text-bold">
        17. Proteção & Obrigações de Informações Confidenciais
      </h4>
      <p className="text-wrapper-22">
        Sua privacidade importa. Nossa Política de Privacidade delineia como
        lidamos com seus dados com cuidado e respeito, em conformidade com os
        requisitos legais.
      </p>
      <p className="text-wrapper-22">
        <strong>17.1</strong> Cada parte compromete-se a não divulgar quaisquer
        informações confidenciais relacionadas aos negócios, clientes, clientes
        ou fornecedores da outra parte a qualquer momento, exceto conforme
        permitido sob a cláusula 18.2.
      </p>
      <p className="text-wrapper-22">
        <strong>17.2</strong> Uma parte pode compartilhar as informações
        confidenciais da outra parte nas seguintes circunstâncias:
      </p>
      <ul className="text-wrapper-22">
        <li>
          Quando necessário a seus empregados, oficiais, representantes ou
          conselheiros que requerem essa informação para cumprir as obrigações
          da parte sob este Acordo. A parte divulgadora deve assegurar que seus
          empregados, oficiais, representantes ou conselheiros que recebam as
          informações confidenciais adiram a esta cláusula de confidencialidade;
          e
        </li>
        <li>
          Quando exigido por obrigações legais, uma ordem judicial ou por
          qualquer autoridade governamental ou regulatória.
        </li>
      </ul>
      <p className="text-wrapper-22">
        <strong>17.3</strong> Nenhuma parte pode utilizar as informações
        confidenciais da outra parte para qualquer propósito que não seja
        cumprir suas responsabilidades dentro do escopo deste Acordo.
      </p>
      <h4 className="text-wrapper-22 text-bold">18. Disposições Gerais</h4>
      <p className="text-wrapper-22">
        Este Contrato, juntamente com a prestação de Serviços e a relação entre
        você e a Wellny, será regido pelas leis dos Estados Unidos e do Estado
        de Delaware. Você concorda inequivocamente com a jurisdição exclusiva
        dos tribunais localizados no Estado de Delaware para quaisquer disputas
        decorrentes deste Contrato. A Convenção das Nações Unidas sobre
        Contratos para a Venda Internacional de Mercadorias não é explicitamente
        aplicável.
      </p>
      <p className="text-wrapper-22">
        <strong>18.1</strong> Se qualquer cláusula deste Contrato for
        considerada inválida ou inaplicável, essa cláusula será eliminada e o
        restante das disposições continuará em vigor.
      </p>
      <p className="text-wrapper-22">
        <strong>18.2</strong> A incapacidade da Wellny de fazer valer ou
        implementar qualquer direito ou disposição contida neste Contrato não
        renuncia ao seu direito de fazê-lo no futuro.
      </p>
      <h4 className="text-wrapper-22 text-bold">19. Perguntas e Feedback</h4>
      <p className="text-wrapper-22">
        Tem uma pergunta ou sugestão? Estamos à disposição. Nosso objetivo é
        tornar sua experiência com a Wellny tão agradável e direta quanto
        possível. Entre em contato a qualquer momento através de
        support@wellny.com.br
      </p>
    </div>
  );
};
